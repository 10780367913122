
import Vue from 'vue'
import { Speaker } from '~/models/speaker'
import { NavigationTabs } from '~/assets/ts/enums'
import {
  images,
  markdownMetadataDescription,
  metadata,
} from '~/assets/ts/utils/metadata'
import { siteSpeakerUrl } from '~/assets/ts/utils/urls'
import SpeakerImage from '~/components/_general/SpeakerImage.vue'
import SiteFollowableButtonStack from '~/components/site/FollowableButtonStack.vue'
import SiteLayoutWithPromo from '~/components/site/layout/WithPromo.vue'
import SiteManageButtons from '~/components/site/ManageButtons.vue'
import NavigationTabList from '~/components/_general/NavigationTabList.vue'
import { GetNavigationSlug } from '~/assets/ts/utils/route'
import SaIcon from '~/components/_general/SaIcon.vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import CustomDropdown from '~/components/_general/CustomDropdown.vue'
import { StructuredDataSpeaker } from '~/assets/ts/utils/structured-data'

export enum SpeakerTab {
  Info = '',
  Sermons = 'sermons',
  Articles = 'articles',
  Series = 'series',
}

export default Vue.extend({
  components: {
    CustomDropdown,
    InlineIcon,
    SaIcon,
    NavigationTabList,
    SiteManageButtons,
    SiteLayoutWithPromo,
    SiteFollowableButtonStack,
    SpeakerImage,
  },
  layout: 'site',
  middleware: ['fetchSpeaker'],
  head(this: any) {
    return metadata(this, {
      title: this.speakerName,
      description: markdownMetadataDescription(this.speaker.bio),
      images: images(this.speaker.albumArt(500), true),
      data: StructuredDataSpeaker(this.speaker),
    })
  },
  computed: {
    currentTab(): SpeakerTab {
      return GetNavigationSlug<SpeakerTab>(this, this.tabs, SpeakerTab.Info)
    },
    tabs(): NavigationTabs {
      return [
        {
          title: this.$t('Info'),
          slug: SpeakerTab.Info,
        },
        {
          title: this.$t('Sermons'),
          slug: SpeakerTab.Sermons,
        },
        {
          title: this.$t('Series'),
          slug: SpeakerTab.Series,
        },
        {
          title: this.$t('Articles'),
          slug: SpeakerTab.Articles,
        },
      ]
    },
    speakerID(): number {
      return parseInt(this.$route.params.speaker)
    },
    speakerName(): string {
      return this.speaker.displayName
    },
    speaker(): Speaker {
      const speakerData = this.$store.getters['speakers/speaker'](
        this.speakerID
      )
      return new Speaker(speakerData)
    },
    speakerSermonCount(): string {
      return this.$tc('{n} Sermon | {n} Sermons', this.speaker.sermonCount, {
        n: this.speaker.sermonCountDisplay,
      })
    },
    speakerUrl(): string {
      return `${siteSpeakerUrl(this.speaker)}/`
    },
  },
})
